import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgForm } from "@angular/forms";

import { Task } from '../../models/task';

@Component({
  selector: 'form-hours',
  templateUrl: './form-hours.component.html',
  styleUrls: ['./form-hours.component.scss']
})
export class FormHoursComponent implements OnInit {

  @Output() onSubmitHoursForm: EventEmitter<Task> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onSubmit(task: Task){
    this.onSubmitHoursForm.emit(task);
  }

}
