import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router } from '@angular/router';

import { TicketService } from 'src/app/core/services/ticket/ticket.service';

@Component({
  selector: 'app-ticket-creator',
  templateUrl: './ticket-creator.component.html',
  styleUrls: ['./ticket-creator.component.scss']
})
export class TicketCreatorComponent implements OnInit {

  constructor(private router: Router, private ticketService: TicketService) { }

  ngOnInit() {
  }

  onTicketCreation({ value }: NgForm) {
    value.createdAt = Date.now();
    this.ticketService.postTicket(value).subscribe(data => this.router.navigate['/tickets']);
  }

}
