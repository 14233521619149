import { FormHoursComponent } from './presentationals/form-hours/form-hours.component';
import { HoursListerComponent } from './presentationals/hours-lister/hours-lister.component';
import { HoursDashboardComponent } from './containers/hours-dashboard/hours-dashboard.component';

export const DECLARATIONS = [
    HoursDashboardComponent,
    FormHoursComponent,
    HoursListerComponent
]

export const EXPORTS = [
    HoursDashboardComponent,
    FormHoursComponent,
    HoursListerComponent
]