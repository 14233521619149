import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { Ticket } from 'src/app/tickets-dashboard/models/ticket';
import { TicketService } from '../../services/ticket/ticket.service';

@Injectable({
  providedIn: 'root'
})
export class TicketResolverService implements Resolve<Ticket[]> {

  constructor(private ticketService: TicketService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Ticket[]> {
    return this.ticketService.getTickets().pipe(take(1));
  }

}
