import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ticket-dashboard',
  templateUrl: './ticket-dashboard.component.html',
  styleUrls: ['./ticket-dashboard.component.scss']
})
export class TicketDashboardComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  go() {
    this.router.navigate(['/tickets/create'])
  }

}
