import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Ticket } from 'src/app/tickets-dashboard/models/ticket';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  
  private getTicketsAPI: string = "https://webhooks.mongodb-stitch.com/api/client/v2.0/app/clientplace-xrgus/service/Tickets/incoming_webhook/GET-tickets";
  private postTicketAPI: string = "https://webhooks.mongodb-stitch.com/api/client/v2.0/app/clientplace-xrgus/service/Tickets/incoming_webhook/POST-ticket"; 
  
  constructor(private http: HttpClient) { }

  getTickets(): Observable<Ticket[]> {
    return this.http.get<Ticket[]>(this.getTicketsAPI);
  }

  postTicket(data: Ticket): Observable<Ticket> {
    return this.http.post<Ticket>(this.postTicketAPI, data);
  }
}
