import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'atx-sidebar-compact',
  templateUrl: './sidebar-compact.component.html',
  styleUrls: ['./sidebar-compact.component.scss']
})
export class SidebarCompactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
