import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';

import { DECLARATIONS_COMPONENTS, EXPORTS_COMPONENTS } from './index';

@NgModule({
    declarations: [
        /* Custom component */
        ...DECLARATIONS_COMPONENTS,
    ],
    imports: [
        /* Angular module */
        CommonModule,
        /* Custom module */
    ],
    exports: [
        /* Custom components */
        ...EXPORTS_COMPONENTS
    ]
})
export class CardsModule { }