import { NavbarComponent } from "./navbar/navbar.component";
import { NavbarItemComponent } from './navbar-item/navbar-item.component';
import { NavComponent } from './nav/nav.component';
import { NavItemComponent } from './nav-item/nav-item.component';

export const DECLARATIONS_COMPONENTS = [
    NavbarComponent,
    NavbarItemComponent,
    NavComponent,
    NavItemComponent
];

export const EXPORTS_COMPONENTS = [
    NavbarComponent,
    NavbarItemComponent,
    NavComponent,
    NavItemComponent

];
