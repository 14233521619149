import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import { DECLARATIONS, IMPORTS, EXPORTS } from './index';

import { SharedModule } from '../shared/shared.module';
import { TicketRoutingModule } from './ticket-routing.module';

@NgModule({
  declarations: [
    ...DECLARATIONS
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    TicketRoutingModule
  ],
  exports: [
    ...EXPORTS,
    TicketRoutingModule
  ]
})
export class TicketsDashboardModule { }
