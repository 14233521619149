import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'atx-nav-item',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./nav-item.component.scss']
})
export class NavItemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
