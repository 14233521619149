import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'sp-navbar',
    templateUrl: './navbar.html',
    styleUrls: []
})
export class NavbarComponent implements OnInit {

    ngOnInit(): void {
    }

}