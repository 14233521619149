import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule }   from '@angular/forms';

import { DECLARATIONS, EXPORTS } from './index';
import { HoursDashboardRoutingModule } from './hours-dashboard.routing';

import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    ...DECLARATIONS
  ],
  imports: [
    FormsModule,
    CommonModule,
    /* Custom modules */
    SharedModule,
    HoursDashboardRoutingModule
  ],
  exports: [
    ...EXPORTS
  ]
})
export class HoursDashboardModule { }
