import { TicketComponent } from './ticket/ticket.component';
import { TicketCreatorComponent } from './ticket-creator/ticket-creator.component';
import { TicketProfileComponent } from './ticket-profile/ticket-profile.component';
import { TicketDashboardComponent } from './ticket-dashboard/ticket-dashboard.component';

import { TicketRoutingModule } from './ticket-routing.module';
import { TicketListerComponent } from './ticket-lister/ticket-lister.component';

export const DECLARATIONS = [
    TicketComponent, 
    TicketCreatorComponent, 
    TicketProfileComponent,
    TicketListerComponent,
    TicketDashboardComponent
]

export const IMPORTS = [
    TicketRoutingModule
]

export const EXPORTS = [
    TicketDashboardComponent
]