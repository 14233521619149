import { Component, OnInit } from '@angular/core';

import { Task } from '../../models/task';
import { TaskService } from 'src/app/core/services/task/task.service';

@Component({
  selector: 'app-hours-dashboard',
  templateUrl: './hours-dashboard.component.html',
  styleUrls: ['./hours-dashboard.component.scss']
})
export class HoursDashboardComponent implements OnInit {

  tasks: Task[];

  constructor(private taskService: TaskService) { }

  ngOnInit() {
    this.taskService
    .getTasks()
    .subscribe(data => this.tasks = data || []);
  }

  /**
   * Arriva dal componente FormHours che emette un nuovo task da salvare
   * @param event : Task
   */
  onSubmitHoursForm(event: Task){
    this.taskService
    .postTask(event)
    .subscribe(data => this.tasks.push(data));
  }

  /**
   * Arriva dal componente HoursLister che emette il task da eliminare
   * TODO: Rivedere i nomi dei compoenenti in base ai tipi che utilizzano
   * @param event : Task
   */
  onDeleteHours(event: Task) {
    this.taskService
    .deleteTask(event.id)
    .subscribe(() => {
      this.tasks = this.tasks.filter(task => task.id !== event.id )
    });
  }
  
  /**
   * 
   */
  onSaveTask() {
    this.taskService
    .postBulkTask(this.tasks)
  }

}
