import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'atx-card-body',
  template: `<div class="card-body"><ng-content></ng-content></div>`,
  styleUrls: []
})
export class CardBodyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
