import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from "rxjs";

/**
 * Non mi piace che il modello si trova su un altro modulo
 * Valutare se spostarlo qui in core
 **/
import { Task } from 'src/app/hours-dashboard/models/task';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private http: HttpClient) { }

  /**
   * Get all Task from database
   */
  getTasks(): Observable<Task[]> {
    return this.http.get<Task[]>('http://localhost:3000/api/task');
  }

  /**
   * Post single task into database
   * @param task 
   */
  postTask(task: Task): Observable<Task> {
    return this.http.post<Task>('https://webhooks.mongodb-stitch.com/api/client/v2.0/app/clientplace-xrgus/service/Clientplace-hours/incoming_webhook/POST-hours', task);
  }
  
  /**
   * Post bulk task into database
   * @param tasks 
   */
  postBulkTask(tasks: Task[]): Observable<Task[]> {
    return this.http.post<Task[]>('http://localhost:3000/api/task', tasks);
  }

  /**
   * Delete one task by id
   * @param id 
   */
  deleteTask(id: String) {
    return this.http.delete(`http://localhost:3000/api/task/${id}`)
  }

}
