import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { Card } from '../models/card.model';
import { Action } from 'src/app/shared/models/action.model';

@Component({
    selector: 'atx-card',
    templateUrl: './card.html',
    styles: []
})
export class CardComponent implements Card{
    @Input() title: String;    
    @Input() subtitle: String;
    @Input() text: String;
    @Input() imgUri: String;
    @Input() actions: Action[];

    @Output() actionClicked: EventEmitter<Action> = new EventEmitter();

    constructor() { }   
    
    onClick($event: Action) {
        this.actionClicked.emit($event)
    }
}