import { CardsModule } from './cards/cards.module';
import { NavbarsModule } from './navbars/navbars.module';
import { FormsModule } from './forms/forms.module';

export const DECLARATIONS = [

]

export const IMPORTS = [
    CardsModule,
    NavbarsModule,
    FormsModule
 
]

export const EXPORTS = [
    CardsModule,
    NavbarsModule,
    FormsModule
]