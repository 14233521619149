import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { Routes, RouterModule } from "@angular/router";

import { TicketResolverService } from "../core/resolvers/ticket/ticket-resolver.service";

import { TicketDashboardComponent } from "./ticket-dashboard/ticket-dashboard.component";
import { TicketListerComponent } from "./ticket-lister/ticket-lister.component";
import { TicketCreatorComponent } from "./ticket-creator/ticket-creator.component";

const routes: Routes = [
  {
    path: "tickets",
    component: TicketDashboardComponent,
    children: [
      {
        path: "create",
        component: TicketCreatorComponent
      },
      {
        path: "",
        resolve: {
          tickets: TicketResolverService
        },
        component: TicketListerComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TicketRoutingModule {}
