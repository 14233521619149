import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DECLARATIONS, IMPORTS, EXPORTS } from './index';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    /* Custom modules */
    ...IMPORTS
  ],
  exports: [
    ...EXPORTS
  ]
})
export class SharedModule { }
