import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "atx-card-header",
  template: `
    <div class="card-header">
      {{ title }}
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: []
})
export class CardHeaderComponent {
  @Input() title: String;

  constructor() {}
}
