import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/* Spostare modelli in core */
import { TimeEntry } from 'src/app/home/models/time-entry';
import { HttpRequest, HttpClient, HttpEvent } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TimeEntryService {

  constructor(private http: HttpClient) { }

  getCurrentTimeEntry(): Observable<TimeEntry>{
    let req = new HttpRequest<TimeEntry>('GET', '/api/time-entry');

    return this.http.get<TimeEntry>(' http://localhost:3000/api/time-entry')
  }
  
}
