import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NbSidebarModule, NbLayoutModule, NbThemeModule, NbActionsModule } from '@nebular/theme';

import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './home/home.module';
import { NavbarsModule } from './shared/navbars/navbars.module';
import { SidebarsModule } from './shared/sidebars/sidebars.module';
import { HoursDashboardModule } from './hours-dashboard/hours-dashboard.module';
import { TicketsDashboardModule } from './tickets-dashboard/tickets-dashboard.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    //
    AppRoutingModule,
    //
    NavbarsModule,
    SidebarsModule,
    HomeModule,
    HoursDashboardModule,
    TicketsDashboardModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { 

}
