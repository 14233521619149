import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Task } from '../../models/task';

@Component({
  selector: 'hours-lister',
  templateUrl: './hours-lister.component.html',
  styleUrls: ['./hours-lister.component.scss']
})
export class HoursListerComponent implements OnInit {

  @Input() tasks: Task[];

  @Output() onDeleteHours: EventEmitter<Task> = new EventEmitter();
  
  constructor() { }

  ngOnInit() {
  }

  onDelete(event: Task){
    this.onDeleteHours.emit(event);
  }
}
