import { NgModule } from "@angular/core";
import { Routes, RouterModule  } from "@angular/router";

import { HoursDashboardComponent } from './containers/hours-dashboard/hours-dashboard.component';

/* Custom components */

const  routes: Routes = [
    { path: 'hours', component: HoursDashboardComponent, pathMatch: 'full' }
 ];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class HoursDashboardRoutingModule { }