import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Card } from '../models/card.model';
import { Action } from 'src/app/shared/models/action.model';

@Component({
    selector: 'sp-card-horizontal',
    template: `
        <div class="card mb-3">
            <div class="row no-gutters">
                <div class="col-md-4">
                    <img [src]="imgUri" class="card-img" alt="...">
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <h5 class="card-title">{{ title }}</h5>
                        <p class="card-text">{{ text }}</p>
                        <div *ngIf="actions && actions[0]" >
                            <a *ngFor="let _action of actions" (click)="onClick(_action)" class="card-link" href="">{{ _action.label }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: []
})
export class CardHorizontalComponent implements Card {
    @Input() title: String;    
    @Input() subtitle: String;
    @Input() text: String;
    @Input() imgUri: String;
    @Input() actions: Action[];
    
    @Output() actionClicked: EventEmitter<Action> = new EventEmitter<Action>();

    constructor() { }

    onClick(action: Action) {
        this.actionClicked.emit(action);
    }


}