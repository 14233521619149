import { Component, OnInit } from '@angular/core';

import { Ticket } from '../models/ticket';
import { Route } from '@angular/compiler/src/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ticket-lister',
  templateUrl: './ticket-lister.component.html'
})
export class TicketListerComponent implements OnInit {

  tickets: Ticket[];

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data => this.tickets = data.tickets);
  }

}
