import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DECLARATIONS_COMPONENTS, EXPORTS_COMPONENTS } from './index';
import { NavComponent } from './nav/nav.component';
import { NavItemComponent } from './nav-item/nav-item.component';

@NgModule({
  declarations: [
    ...DECLARATIONS_COMPONENTS,
    NavComponent,
    NavItemComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [ 
    ...EXPORTS_COMPONENTS
  ]
})
export class NavbarsModule { }
