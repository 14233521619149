import { Component, Input } from "@angular/core";

@Component({
  selector: "atx-card-footer",
  template: `
    <div class="card-footer">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: []
})
export class CardFooterComponent {
  @Input() description: String;

  constructor() {}
}
