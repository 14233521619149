import { NgModule } from "@angular/core";
import { Routes, RouterModule  } from "@angular/router";

/* Custom components */
import { HomeComponent } from './containers/home/home.component';

const  routes: Routes = [
    { path: 'home', component: HomeComponent, pathMatch: 'full' }
 ];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class HomeRoutingModule { }