import { CardHeaderComponent } from './card-header/card-header.component';
import { CardComponent } from './card/card.component';
import { CardHorizontalComponent } from './card-horizontal/card-horizontal.component';
import { CardGroupComponent } from './card-group/card-group.component';
import { CardDeckComponent } from './card-deck/card-deck.component';
import { CardColumnsComponent } from './card-columns/card-columns.component';
import { CardFooterComponent } from './card-footer/card-footer.component';
import { CardBodyComponent } from './card-body/card-body.component';

/** 
 * Valutare se è opportuno separare i due array in file separati magari 
 * con l'evoluzione dell'ui kit
 */

export const DECLARATIONS_COMPONENTS = [
    CardHeaderComponent,
    CardBodyComponent,
    CardFooterComponent,
    CardComponent,
    CardHorizontalComponent,
    CardGroupComponent,
    CardDeckComponent,
    CardColumnsComponent
]

export const EXPORTS_COMPONENTS = [
    CardHeaderComponent,
    CardBodyComponent,
    CardFooterComponent,
    CardComponent,
    CardHorizontalComponent,
    CardGroupComponent,
    CardDeckComponent,
    CardColumnsComponent
]