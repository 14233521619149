import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SidebarCompactComponent } from './sidebar-compact/sidebar-compact.component';

@NgModule({
  declarations: [
    SidebarCompactComponent
  ],
  imports: [
    CommonModule
  ]
})
export class SidebarsModule { }
