import { Component, OnInit, Input } from '@angular/core';
import { Ticket } from '../models/ticket';

@Component({
  selector: 'ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {
  @Input() author: String;
  @Input() title: String;
  @Input() description: String;
  @Input() createdAt: Date | String;

  constructor() { }

  ngOnInit() {
  }

}
