import { Component, OnInit } from '@angular/core';

import { TimeEntryService } from 'src/app/core/services/time-entry/time-entry.service';
import { TimeEntry } from '../../models/time-entry';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  currentTimeEntry: TimeEntry;
  
  constructor(private timeEntryService: TimeEntryService) { }

  ngOnInit() {
    this.timeEntryService
    .getCurrentTimeEntry()
    .subscribe(data => {
      this.currentTimeEntry = data[0];
    })
  }

}
